import { useMutationCreateReconciliation, useToasts } from '@/hooks'
import { TOAST_VARIANTS } from '@/providers'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    UseDisclosureProps
} from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import { useCallback, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

interface TransactionExcludeConfirmationModalProps extends UseDisclosureProps {
    uuid?: Uuid
}

export const TransactionExcludeConfirmationModal: React.FC<TransactionExcludeConfirmationModalProps> = ({
    uuid,
    isOpen,
    onClose = noop
}) => {
    const containerRef = useRef(globalThis.document.getElementById('root'))
    const cancelRef = useRef(null)

    const { onAdd } = useToasts()
    const mutation = useMutationCreateReconciliation({
        onSuccess(response) {
            onClose(response)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.accounts.transactions.actions.exclude.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.accounts.transactions.actions.exclude.success.toast.description" />
                )
            })
        }
    })
    const onCloseWithLoading = useCallback(() => {
        if (mutation.isPending) {
            return
        }

        onClose()
    }, [mutation, onClose])

    const onExcludeTransaction = useCallback(() => {
        if (!uuid) {
            return
        }
        mutation.mutate({
            transaction_id: uuid
        })
    }, [mutation, uuid])

    return (
        <AlertDialog
            isOpen={isOpen as boolean}
            leastDestructiveRef={cancelRef}
            onClose={onCloseWithLoading}
            portalProps={{ containerRef }}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <FormattedMessage id="app.accounts.transactions.exclude_modal.title" />
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <FormattedMessage id="app.accounts.transactions.exclude_modal.description" />
                    </AlertDialogBody>
                    <AlertDialogFooter gap="16px">
                        <Button
                            variant="secondary"
                            ref={cancelRef}
                            onClick={onCloseWithLoading}
                            disabled={mutation.isPending}>
                            <FormattedMessage id="app.common.actions.cancel.label" />
                        </Button>
                        <Button onClick={onExcludeTransaction} isLoading={mutation.isPending}>
                            <FormattedMessage id="app.common.form.actions.confirm.label" />
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
