import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { APIError } from '@/types'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { Reconciliation, ReconciliationCreateOrUpdateData } from '@webapps/numeral-ui-core'
import { AxiosError } from 'axios'
import { noop } from 'lodash'

export function useMutationCreateReconciliation(
    options?: MutationOptions<
        Reconciliation,
        AxiosError<APIError<ReconciliationCreateOrUpdateData>>,
        ReconciliationCreateOrUpdateData
    >
) {
    const { selectedUserAccess } = useAuth()
    const { reconciliationsService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<
        Reconciliation,
        AxiosError<APIError<ReconciliationCreateOrUpdateData>>,
        ReconciliationCreateOrUpdateData
    >({
        ...options,
        mutationKey: [reconciliationsService.url],
        mutationFn(data) {
            return reconciliationsService.createReconciliation(data, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [reconciliationsService.url]
                })
                .catch(noop)
        }
    })
}
